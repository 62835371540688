import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 팝업정보
 */
class Popup {
    /**
     * 생성자
     */
    constructor() {
        //this.systCode = process.env.VUE_APP_SYSTEM_CODE;
    }

    /**
     * 팝업 정보 목록 조회
     * @param {*} systCode 
     * @param {*} useYn 
     * @returns 
     */
    getPopupInfoList(systCode, useYn) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz';
            request.methodId = 'GetPopupInfo';
            request.parameters.systCode = systCode
            request.parameters.useYn = useYn

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }
}

export const popup = new Popup();