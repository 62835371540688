<template>
    <div class="admin-content">
        <h3>팝업관리</h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button class="btn-box" @click="methods.getPopupInfoList" >조회</button>
                    <button class="btn-box green" @click="methods.showNewDetail">신규</button>
                </div>
            </div>
            <div>
                <div class="sch-wrap3">
                    <div>
                        <label>계정 유형</label>
                        <div class="select-box">
                            <select id="selectSystCode" v-model="state.dataTable.selectedSystemCode"/>
                        </div>
                    </div>
                    <div>
                        <label>사용여부</label>
                        <div class="select-box">
                            <select id="selectUseUn" v-model="state.dataTable.selectedUseYn">
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 총 개수 -->
        <div class="total-box">
            <p>Total <strong>{{state.dataTable.popupItems === null? 0 : state.dataTable.popupItems.length}}</strong></p>
        </div>

        <DataTable class="p-datatable-sm" 
            columnResizeMode="fit"
            scrollHeight="497px"
            :value="state.dataTable.popupItems"
            :resizableColumns="true"
            :rowHover="true" 
            @row-click="eventHandler.dataTable.onRowClick" >

            <template #empty>
              데이터가 없습니다.
            </template>
            
            <Column field="SystCode" header="시스템코드" :sortable="true" style="width:200px" headerClass="tc"></Column>
            <Column field="PopupName" header="팝업명" :sortable="true" style="width:400px" headerClass="tc"></Column>
            <Column field="PopupPath" header="팝업 파일 경로" :sortable="true" style="width:700px;" headerClass="tc">
                <template #body="{data}">
                        <p class="text-overflow1">
                            {{data.PopupPath}}
                        </p>
                </template>
            </Column>
            <Column field="Description" header="설명" :sortable="true" style="width:400px" headerClass="tc"></Column>
            <Column field="ContentMaintain" header="유지기간" :sortable="true" style="text-align:center; width:200px;" headerClass="tc"></Column>
            <Column field="UseYn" header="사용여부" :sortable="true" style="text-align:center; width:150px;" headerClass="tc"></Column>

        </DataTable>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { popup } from '@/modules/system/PCM_SM0650E.js';
import { common } from '@/modules/common/common.js';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import router from '@/router'
export default {
    components: {
    },
    setup() {

        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();

        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {

                // 팝업 목록
                popupItems: null,

                // 선택된 시스템코드
                selectedSystemCode: "ADM",

                // 선택된 사용여부
                selectedUseYn: "Y",

            },
        })

        // 메서드
        const methods = {

            // 팝업정보 조회
            getPopupInfoList: () => {

                // 스토어 값 변경
                if (store.getters.isLoading === false) {

                  store.commit("setLoadingStatus", true);
                }

                popup.getPopupInfoList(
                  state.dataTable.selectedSystemCode,
                  state.dataTable.selectedUseYn).then(result => {
                    state.dataTable.popupItems = result;
                  }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 신규 상세 호출
            showNewDetail: () => {
                var q = vrt.query;
                q["NewInsert"] = "Y";
                q["SystCode"] = state.dataTable.selectedSystemCode;
                router.push({ name: 'PCM_SM0651E', query: q });
            }
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련 이벤트 핸들러
            dataTable: {

                // 행 클릭 이벤트 처리
                onRowClick: event => {
                  var q = vrt.query;
                  q["NewInsert"] = "N";
                  q["Seq"] = event.data.Seq;
                  q["SystCode"] = event.data.SystCode;
                  router.push({ name: 'PCM_SM0651E', query: q });
                },
            },
        }

        onMounted(() => {

            // 시스템 코드 초기조회
            common.getSystemCodeInfo('SYSTEM', '')
              .then(result => {
                const obj = document.getElementById("selectSystCode");
                obj.options.length = 0;
                for (const key in result) {
                  if (typeof(result[key]) === 'object')
                  obj.options[obj.options.length] = new Option(result[key]["CodeName"], result[key]["CodeValue"]);
                }
              });

            // 팝업 정보 초기조회
            methods.getPopupInfoList();
        })

        return {
          state,
          popup,
          common,
          methods,
          eventHandler,
        }
    }
}
</script>